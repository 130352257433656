<template>
  <div>
    <tabs v-model="tab" :tabs="tabs" class="mb-6" />
    <component
      :is="tab"
      :branches="branches"
      :regions="regions"
      :profile-id="profileId"
      @success="$emit('success')"
    />
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object]
    }
  },
  data() {
    return {
      tab: 'branch'
    };
  },
  components: {
    branch: () => import('./Branch.vue'),
    region: () => import('./Region.vue')
  },
  computed: {
    tabs() {
      return [
        { title: 'Branch', name: 'branch' },
        { title: 'Region', name: 'region' }
      ];
    },
    branches() {
      return this.profile?.branches ?? [];
    },
    regions() {
      return this.profile?.regions ?? [];
    },
    profileId() {
      return this.profile?.id;
    }
  }
};
</script>
